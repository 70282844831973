
import { Component, Vue, Watch } from "vue-property-decorator";
import PayCardForm from "../components/payment/PayCardForm.vue";
import { PayCardInfo } from "../components/payment";

@Component({
  components: { PayCardForm }
})
export default class TestView extends Vue {
  cardInfo: PayCardInfo = {
    cardName: "",
    cardNumber: "",
    cardExpiry: "",
    cardCvv: ""
  };

  @Watch("cardInfo")
  onCardInfo(val: PayCardInfo) {
    console.log("Value changed", { ...val });
  }
}
