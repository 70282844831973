export function getAnyProp(subject: unknown, ...path: string[]): unknown {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result = subject as any;
  for (const prop of path) {
    if (!result) break;
    if (prop in result) {
      result = result[prop];
    } else {
      return null;
    }
  }
  return result;
}

export function isEmptyObject(value: unknown): boolean {
  if (value === undefined || value === null) return true;
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === "object" && Object.keys(value || {}).length === 0) {
    return true;
  }
  return false;
}
