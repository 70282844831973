
import { Component, Vue } from "vue-property-decorator";
import { logError, logInfo } from "@/util";
import { TransactionInfoResponse } from "@/domain/types";
import { getStrings } from "@/lib/language";
import TxnStatusPanel, { TxnStatusPanelID } from "@/components/TxnStatusPanel.vue";

@Component({
  components: { TxnStatusPanel }
})
export default class BizumRedirect extends Vue {
  showResult = false;
  txnInfo: Partial<TransactionInfoResponse> = {};
  requestID = "";

  get langKey() {
    return this.txnInfo?.language;
  }

  get lang() {
    return getStrings(this.langKey);
  }

  txnStatusPanel() {
    return this.$refs.txnStatusPanel as TxnStatusPanel;
  }

  setPanelID(panelID: TxnStatusPanelID | "result") {
    this.showResult = panelID === "result";
    if (panelID === "result") {
      this.txnStatusPanel().setPanel(null);
    } else {
      this.txnStatusPanel().setPanel(panelID);
    }
  }

  get accentColor(): string {
    return "primary";
  }

  async created() {
    const requestID = this.$route.params["requestID"];
    if (!requestID) {
      logError("Result: Invalid transaction ID", null, { requestID });
      return;
    }
    logInfo(`Multiple Challenge Send: ${requestID}`);
    this.requestID = requestID;
    this.$nextTick(() => {
      this.processParameters();
    });
  }

  async processParameters() {
    try {
      this.setPanelID("loading");
      await this.$puceApp.backend.queryChallengeStatus(this.requestID);
      this.setPanelID("error");
      logInfo("Transaction Info", this.txnInfo);
    } catch (err) {
      logError(`Error loading transaction info '${this.requestID}'`, err as Error, { requestID: this.requestID });
      this.setPanelID("errorRetry");
    }
  }
}
