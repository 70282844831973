import { BrowserInfo } from "./types";

/* browserData = {
  browserColorDepth: "1",
  browserJavaEnabled: false,
  browserLanguage: "es-es",
  browserScreenHeight: "0",
  browserScreenWidth: "0",
  browserTZ: "0"
}; */

export function gatherBrowserData(): BrowserInfo {
  const d = new Date();
  return {
    browserColorDepth: screen.colorDepth.toString(),
    browserLanguage: navigator.language,
    browserScreenHeight: screen.height.toString(),
    browserScreenWidth: screen.width.toString(),
    browserTZ: d.getTimezoneOffset().toString(),
    browserJavaEnabled: false,
    browserJavascriptEnabled: true
  };
}
