import { logError } from "@/util";

export interface LangData {
  [key: string]: string;
}

export type LangKey = "EN" | "ES" | "DE" | "FR" | "IT" | "RU" | "PT";
export type LangKeyLike = LangKey | string | null;

export interface LanguageName {
  key: LangKey;
  name: string;
}

export const languageNames: LanguageName[] = [
  { key: "DE", name: "Deutsch" },
  { key: "EN", name: "English" },
  { key: "ES", name: "Español" },
  { key: "FR", name: "Français" },
  { key: "IT", name: "Italiano" },
  { key: "PT", name: "Português" },
  { key: "RU", name: "русский" }
];

export function getLanguageNames() {
  return languageNames;
}

function detectAppLanguage(): LangKey {
  try {
    let lang = "";
    if (typeof navigator !== "undefined") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      lang = navigator.language || ((navigator as any).userLanguage as string);
      if (!lang && navigator.languages && navigator.languages.length > 0) {
        lang = navigator.languages[0];
      }
    }
    const parts = lang.split("-");
    return getValidLang(parts[0]);
    // return getValidLang(parts[0] || "");
  } catch (err) {
    logError("Error detecting language", err as Error);
    return "EN";
  }
}

export const defaultLangKey: LangKey = detectAppLanguage();

export type LangDic = Partial<Record<LangKey, LangData>>;
// Old type >>> [key: string]: LangData;

export function toLangKey(langKey?: LangKeyLike): LangKey {
  if (!langKey) return defaultLangKey;
  return langKey as LangKey;
}

function getValidLang(lang = "EN"): LangKey {
  lang = lang.toLocaleUpperCase();
  let selectedLang = "EN";
  if (lang.length > 0) {
    getLanguageNames().map((languaje: { key: LangKey }) => {
      if (lang === languaje.key) selectedLang = languaje.key;
    });
  }
  if (lang === "ES" || lang === "CT" || lang === "VL" || lang === "EU" || lang === "GA") selectedLang = "ES";
  return selectedLang as LangKey;
}

const allStrings: LangDic = {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  EN: require("./strings/transaction.EN").default,
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  ES: require("./strings/transaction.ES").default,
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  DE: require("./strings/transaction.DE").default,
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  FR: require("./strings/transaction.FR").default,
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  IT: require("./strings/transaction.IT").default,
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  RU: require("./strings/transaction.RU").default,
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  PT: require("./strings/transaction.PT").default
};

function fillStrings(langs: LangDic, langKey?: LangKeyLike, defaultKey?: LangKeyLike): LangData {
  const data = langs[toLangKey(langKey)];
  const defaultData = langs[toLangKey(defaultKey)];
  if (!data) return defaultData || {};
  return {
    ...defaultData,
    ...data
  };
}

export function getLanguages(): LangKey[] {
  return Object.keys(allStrings) as LangKey[];
}

export function getStrings(langKey?: LangKeyLike, keepEmpty = false): LangData {
  if (!keepEmpty) {
    return fillStrings(allStrings, langKey);
  } else {
    return allStrings[toLangKey(langKey)] || {};
  }
}
