import countries from "./strings/countries.json";

export interface LangCountries {
  [key: string]: string;
}
interface CountryDir {
  [lang: string]: LangCountries;
}

export function getLangCountries(langName: string): LangCountries {
  const names = countries as unknown as CountryDir; // Hack to read it as dictionary
  const data = names[langName];
  if (!data) return countries["EN"];
  return data;
}
