import { LangData } from "../lang";

const values: LangData = {
  btnGoBack: "Назад",
  btnPay: "Безопасный платеж",
  cfTitleCardData: "Кредитная/дебетовая карта",
  cfTitleExtraData: "Платежные реквизиты ",
  ciCardNameTitle: "ФИО",
  ciCardNumberTitle: "Номер карты",
  ciExpireLabel1: "до",
  ciExpireLabel2: "",
  ciExpireTitle: "месяц / год",
  ciSecurityCodeTitle: "Код подтверждения",
  dccButtonSubmit: "Подтвердите оплату",
  dccFormMessage: "Пожалуйста, выберите валюту оплаты:",
  dccPageTitle: "Выбор валюты",
  dccRemaining: "Через %1 секунд, будет автоматически выбран Евро",
  errCardInvalid: "Неверный номер карты",
  errCardNameRequired: "Поле обязательно для заполнения",
  errCardRequired: "Введите реквизиты банковской карты",
  errExpirationInvalid: "Срок действия должен быть ММ/ГГ",
  errExpirationRequired: "Введите срок действия",
  errSecurityCodeInvalid: "Неверный код",
  errSecurityCodeRequired: "Код безопасности",
  fAddressLine1Label: "Адрес 1",
  fAddressLine1Placeholder: "Abedul 1234",
  fAddressLine2Label: "Адрес 2",
  fAddressLine2Placeholder: "Квартира 1А",
  fAddressMatchShipping: "Адрес доставки совпадает с адресом выставления счета? ",
  fBillingAddress: "Адрес выставления счета",
  fCardNameLabel: "ФИО держателя",
  fCardNamePlaceholder: "-",
  fCityLabel: "Город",
  fCityPlaceholder: "Pozuelo de Alarcón",
  fCountryLabel: "Страна",
  fCountryPlaceholder: "Испания",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  fEmailLabel: "Электронная почта ",
  fEmailPlaceholder: "имя@пример.com",
  fExpireLabel: "ММ / ГГ",
  fExpirePlaceholder: "__/__",
  fHomePhoneLabel: "Домашний телефон",
  fMobilePhoneLabel: "Мобильный телефон",
  fPANLabel: "Номер карты",
  fPANPlaceholder: "···· ···· ···· ····",
  fPhonePlaceholder: "+34...",
  fPostCodeLabel: "Индекс",
  fPostCodePlaceholder: "28223",
  fShippingAddress: "Адрес доставки ",
  fStateLabel: "Провинция",
  fStatePlaceholder: "Мадрид",
  fWorkPhoneLabel: "Рабочий телефон",
  footerMessage: "использует PAYTEF для безопасной обработки сделок.",
  footerPoweredBy: "Powered by PAYTEF",
  logoSimulationChip: "Симуляция",
  resDate: "Дата оплаты",
  resHCPMessage: "Сообщение HCP",
  resMessageSubtitle: "Процесс оплаты завершен",
  resMessageTitle: "Спасибо! Оплата успешно проведена.",
  resRunningTitle: "обработка…",
  resRunningSubtitle: "Сделка еще не завершена",
  resDeniedTitle: "Платеж отклонен",
  resDeniedSubtitle: "Платеж не принят, проверьте информацию и попробуйте еще раз",
  resMethod: "Вы оплатили",
  resPrice: "Общая сумма",
  resReference: "Номер  #",
  ruleEmail: "Проверьте электронную почту",
  ruleMaxLength: "Максимум %0 знаков",
  ruleNumeric: "Это поле допускает только цифры",
  ruleRequired: "Поле обязательно для заполнения",
  tspErrLoad1: "При попытке оплаты произошла ошибка.",
  tspErrLoad2: " Попробуйте обновить страницу браузера или повторить попытку через несколько минут",
  tspErrLoad3: "Если проблема не устраняется, пожалуйста, проверьте ссылку или свяжитесь с продавцом.",
  tspErrLoadTitle: "Произошла ошибка при загрузке запроса ",
  tspErrProcessed1: "Сделка, которую вы пытаетесь провести, уже обработана и завершена.",
  tspErrProcessed2:
    "Если вы хотите увидеть результат, нажмите на кнопку ниже и мы перенаправим вас на страницу результата.",
  tspErrProcessedButton: "Перейти на страницу результатов",
  tspErrProcessedTitle: "Эта сделка уже обработана",
  tspErrExpired1:
    "Транзакция не может быть обработана, потому что с момента запуска процесса прошло слишком много времени",
  tspErrExpired2: "Оплату можно повторить со страницы магазина. Нажмите кнопку, чтобы перейти на страницу результатов",
  tspErrExpiredButton: "Перейти на страницу результатов",
  tspErrExpiredTitle: "Срок действия транзакции истек",
  tspLoadingDesc: "Пожалуйста, подождите…",
  tspLoadingTitle: "Загрузка данных",
  tspProcessingDesc: "Пожалуйста, подождите…",
  tspProcessingTitle: "Сделка обрабатывается",
  txnPanelTitle: "Оплата картой",
  resTokenSubtitle: "Завершен процесс токенизации.",
  resTokenTitle: "Спасибо! успешная токенизация.",
  txnTokenAmount: "Токенизация"
};

export default values;
