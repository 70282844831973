import * as cardValidator from "card-validator";
import * as util from "@/util";
import { LangData } from "@/lib/language";
import { CardLang, CardBrand } from "./types";
import { brandDetails, defaultLang } from "./constants";
import { getCardBrandTheme } from "./cardBrand";

export class PayCardManager {
  lang: CardLang;
  cardNameEnabled: boolean;
  cardNameMandatory: boolean;

  constructor(lang: LangData, cardNameEnabled: boolean, cardNameMandatory: boolean) {
    this.lang = {
      ...defaultLang,
      ...(lang as CardLang)
    };
    this.cardNameEnabled = cardNameEnabled;
    this.cardNameMandatory = cardNameMandatory;
  }

  expiry() {
    return {
      label: this.lang.fExpireLabel,
      placeholder: this.lang.fExpirePlaceholder,
      mask: "##/##"
    };
  }

  private isExpirationValid(value?: string) {
    if (!value) return false;
    const v = cardValidator.expirationDate(value);
    return v.isPotentiallyValid;
  }

  cvv() {
    return {
      label: this.lang.fCvvLabel,
      placeholder: this.lang.fCvvPlaceholder,
      mask: "####"
    };
  }

  private isCVVValue(code?: string, codeLen?: number) {
    return !code || !codeLen || code.length === codeLen;
  }

  cardName() {
    return {
      label: this.lang.fCardNameLabel,
      placeholder: this.lang.fCardNamePlaceholder
    };
  }

  pan(partialPAN: string) {
    const mask = this.findMask(partialPAN);
    return {
      label: this.lang.fPANLabel,
      placeholder: this.lang.fPANPlaceholder,
      brand: mask.brand,
      mask: mask.mask
    };
  }

  private findMask(partialPAN: string) {
    const number = partialPAN.replace(/\D/g, "");
    for (let i = 0; i < brandDetails.length; i++) {
      const regex = brandDetails[i].regex;
      if (regex) {
        const re = new RegExp(regex);
        if (number.match(re) != null) {
          return brandDetails[i];
        }
      }
    }
    return brandDetails[brandDetails.length - 1];
  }

  getTheme(brand: CardBrand) {
    return getCardBrandTheme(brand);
  }

  validationRules() {
    return {
      cardName:
        this.cardNameEnabled && this.cardNameMandatory ? [util.rules.required(this.lang.errCardNameRequired)] : [],
      pan: [
        util.rules.required(this.lang.errCardRequired),
        (cardNum: string) => cardValidator.number(cardNum).isValid || this.lang.errCardInvalid
      ],
      cvv: [
        util.rules.required(this.lang.errSecurityCodeRequired),
        // TODO: Cambiar code length y hacerlo dinámico
        (code: string) => this.isCVVValue(code, 3) || this.isCVVValue(code, 4) || this.lang.errSecurityCodeInvalid
      ],
      expiry: [
        util.rules.required(this.lang.errExpirationRequired),
        (value: string) => {
          return this.isExpirationValid(value) || this.lang.errExpirationInvalid;
        }
      ]
    };
  }
}
