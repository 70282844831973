
import { Component, Vue } from "vue-property-decorator";
import * as app from "@/application";
import { logError, logInfo } from "@/util";
import { DCCInformation, RedirectResponse, TransactionInfoResponse } from "@/domain/types";
import TxnProcessForm from "@/components/TxnProcessForm.vue";
import TxnProcessBizumForm from "@/components/TxnProcessBizumForm.vue";
import TxnStatusPanel, { TxnStatusPanelID } from "@/components/TxnStatusPanel.vue";
import DccChoice from "@/components/DccChoice.vue";
import { getStrings } from "@/lib/language";

type UIPanel = TxnStatusPanelID | "dcc";

@Component({
  components: { TxnProcessForm, TxnStatusPanel, DccChoice, TxnProcessBizumForm }
})
export default class TxnDirect extends Vue implements app.TxnView {
  panelID: UIPanel = "loading";
  requestID = "";
  txnInfo: TransactionInfoResponse | null = null;
  dccInfo: DCCInformation | null = null;
  showCardForm = false;

  get langKey() {
    return this.txnInfo?.language;
  }
  get lang() {
    return getStrings(this.langKey);
  }

  get accentColor(): string {
    return app.getPrimaryColor(this.txnInfo?.merchant);
  }

  txnProcessForm(): TxnProcessForm {
    return this.$refs.txnProcessForm as TxnProcessForm;
  }

  txnStatusPanel() {
    return this.$refs.txnStatusPanel as TxnStatusPanel;
  }

  setPanelID(panelID: UIPanel) {
    this.panelID = panelID;
    this.txnStatusPanel().setPanel(panelID === "dcc" ? null : panelID);
  }

  async created() {
    const requestID = this.$route.params["requestID"];
    if (!requestID) {
      logError("TxnDirect: Invalid transaction ID", null, { requestID });
      return;
    }
    logInfo(`TxnDirect created: ${requestID}`);
    this.requestID = requestID;
    this.$nextTick(() => {
      this.processParameters();
    });
  }

  async processParameters() {
    if (await this.loadTransactionInfo()) {
      await this.processTransaction();
    }
  }

  async loadTransactionInfo() {
    try {
      this.setPanelID("loading");
      this.txnInfo = await this.$puceApp.backend.transactionInfo(this.requestID);
      logInfo("Transaction Info", this.txnInfo);
      if (this.txnInfo && this.txnInfo.dccInfo) {
        this.onDCCInfo(this.txnInfo.dccInfo);
        return false;
      }
      return true;
    } catch (err) {
      logError(`Error loading transaction info '${this.requestID}'`, err as Error, { requestID: this.requestID });
      this.setPanelID("errorRetry");
      return false;
    }
  }

  onDCCInfo(dccInfo: DCCInformation) {
    this.dccInfo = dccInfo;
    this.setPanelID("dcc");
  }

  async processTransaction() {
    try {
      if (this.txnInfo?.shouldBeExpired) {
        this.setPanelID("expired");
      } else if (this.txnInfo?.result) {
        this.setPanelID("alreadyProcessed");
      } else {
        this.setPanelID("processing");
        // BIZUM
        if (this.txnInfo?.paymentMethod === "bizum") {
          //START BIZUM PAYMENT
          const res = await this.$puceApp.backend.startBizumPayment(this.requestID, this.txnInfo.bizumPayment.phone);
          if (res.data.method === "redirect") {
            // REDIRECT
            (this.$refs.txnformBizum as TxnProcessBizumForm).submitRedirectForm(res.data as RedirectResponse);
          } else if (res.data?.method === "rtp" || res.data?.method === "validation") {
            // RTP | VALIDATION FAILED (phone without Bizum)
            await this.$puceApp.backend.queryBizumPaymentStatus(this.requestID);
          }
        } else {
          // CARD | TOKEN
          this.showCardForm = true;
          await this.txnProcessForm().startProcess(this.requestID, null);
        }
      }
    } catch (err) {
      logError(`Start process prepare failed '${this.requestID}'`, err as Error, { requestID: this.requestID });
      this.setPanelID("error");
    }
  }
}
