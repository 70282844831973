
import { Component, Vue } from "vue-property-decorator";
import dayjs from "dayjs";
import { logError, logInfo, moneyToString } from "@/util";
import { TransactionInfoResponse } from "@/domain/types";
import { getStrings } from "@/lib/language";
import { getPaytefCardCodeInfo } from "@/components/payment";
import PoweredBy from "@/components/PoweredBy.vue";
import MerchantLogo from "@/components/MerchantLogo.vue";
import TxnStatusPanel, { TxnStatusPanelID } from "@/components/TxnStatusPanel.vue";

@Component({
  components: { PoweredBy, MerchantLogo, TxnStatusPanel }
})
export default class ResultView extends Vue {
  showResult = false;
  txnInfo: Partial<TransactionInfoResponse> = {};
  requestID = "";

  get langKey() {
    return this.txnInfo?.language;
  }

  get lang() {
    return getStrings(this.langKey);
  }

  txnStatusPanel() {
    return this.$refs.txnStatusPanel as TxnStatusPanel;
  }

  setPanelID(panelID: TxnStatusPanelID | "result") {
    this.showResult = panelID === "result";
    if (panelID === "result") {
      this.txnStatusPanel().setPanel(null);
    } else {
      this.txnStatusPanel().setPanel(panelID);
    }
  }

  get accentColor(): string {
    return "primary";
  }

  get resultPanelData() {
    if (!this.txnInfo.result) {
      return {
        iconColor: "warning",
        icon: "fa-hourglass-half",
        title: this.lang.resRunningTitle,
        description: this.lang.resRunningSubtitle,
        showContact: true
      };
    } else if (this.txnInfo.result.approved) {
      const isToken = this.txnInfo?.opType === "token";
      return {
        iconColor: "success",
        icon: "fa-check",
        title: isToken ? this.lang.resTokenTitle : this.lang.resMessageTitle,
        description: isToken ? this.lang.resTokenSubtitle : this.lang.resMessageSubtitle,
        showContact: false
      };
    } else {
      return {
        iconColor: "error",
        icon: "fa-exclamation",
        title: this.lang.resDeniedTitle,
        description: this.lang.resDeniedSubtitle,
        showContact: true
      };
    }
  }

  async created() {
    const requestID = this.$route.query["requestID"] as string;
    if (!requestID) {
      logError("Result: Invalid transaction ID", null, { requestID });
      return;
    }
    logInfo(`TxnProcess created: ${requestID}`);
    this.requestID = requestID;
    this.$nextTick(() => {
      this.processParameters();
    });
  }

  async processParameters() {
    try {
      this.setPanelID("loading");
      this.txnInfo = await this.$puceApp.backend.transactionInfo(this.requestID);
      logInfo("Transaction Info", this.txnInfo);
      this.setPanelID("result");
    } catch (err) {
      logError(`Error loading transaction info '${this.requestID}'`, err as Error, { requestID: this.requestID });
      this.setPanelID("errorRetry");
    }
  }

  get txnAmount(): string {
    if (this.txnInfo?.opType === "token") {
      return "Token";
    }
    return moneyToString(this.txnInfo?.amount, this.txnInfo?.currency);
  }

  get txnPayDate() {
    const date = this.txnInfo.result?.resultDate;
    if (!date) return "";
    return dayjs(date).format("DD/MM/YYYY HH:mm");
  }

  get txnCardDetails() {
    const ccCode = this.txnInfo.result?.cardInfo?.cardTypeCode;
    const ccDigits = this.txnInfo?.result?.cardInfo?.cardLastDigits;
    const cdetails = getPaytefCardCodeInfo(ccCode);
    return {
      show: !!ccDigits,
      ...(cdetails || {}),
      lastDigits: ccDigits
    };
  }
}
