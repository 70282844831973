import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { PuceApp } from "./domain/app";
import "@/styles/common.scss";

Vue.config.productionTip = false;
Vue.prototype.$puceApp = new PuceApp();

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
});
app.$mount("#app");
