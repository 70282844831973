import { LangData } from "../lang";

const values: LangData = {
  btnGoBack: "Back",
  btnPay: "Secure Pay",
  cfTitleCardData: "Credit/Debit Card",
  cfTitleExtraData: "Billing information",
  ciCardNameTitle: "Cardholder's name",
  ciCardNumberTitle: "Card number",
  ciExpireLabel1: "valid",
  ciExpireLabel2: "until",
  ciExpireTitle: "month/year",
  ciSecurityCodeTitle: "Security code",
  dccButtonSubmit: "Confirm Payment",
  dccFormMessage: "Please choose the currency:",
  dccPageTitle: "Currency choice",
  dccRemaining: "Euros will be automatically selected in %1 seconds",
  errCardInvalid: "Card number invalid",
  errCardNameRequired: "Mandatory field",
  errCardRequired: "Enter the card number",
  errExpirationInvalid: "Expiration must be MM / YY",
  errExpirationRequired: "Expiration date is necessary",
  errSecurityCodeInvalid: "Invalid code",
  errSecurityCodeRequired: "Security code is needed",
  fAddressLine1Label: "Address 1",
  fAddressLine1Placeholder: "Abedul 1234",
  fAddressLine2Label: "Address 2",
  fAddressLine2Placeholder: "Piso 1A",
  fAddressMatchShipping: "Billing address is the same as shipping address?",
  fBillingAddress: "Billing address",
  fCardNameLabel: "Cardholder's name",
  fCardNamePlaceholder: "-",
  fCityLabel: "Town/City",
  fCityPlaceholder: "Pozuelo de Alarcón",
  fCountryLabel: "Country",
  fCountryPlaceholder: "España",
  fCvvLabel: "Security code",
  fCvvPlaceholder: "XXX",
  fEmailLabel: "E-mail",
  fEmailPlaceholder: "name@example.com",
  fExpireLabel: "MM / YY",
  fExpirePlaceholder: "__/__",
  fHomePhoneLabel: "Home phone",
  fMobilePhoneLabel: "Mobile phone",
  fPANLabel: "Card number",
  fPANPlaceholder: "···· ···· ···· ····",
  fPhonePlaceholder: "+34...",
  fPostCodeLabel: "Postcode",
  fPostCodePlaceholder: "28223",
  fShippingAddress: "Shipping address",
  fStateLabel: "Province",
  fStatePlaceholder: "Madrid",
  fWorkPhoneLabel: "Work phone",
  footerMessage: "uses PAYTEF to provide secure payment processing.",
  footerPoweredBy: "Powered by PAYTEF",
  logoSimulationChip: "Simulation",
  resDate: "Date paid",
  resHCPMessage: "HCP Message",
  resMessageSubtitle: "The payment process was concluded successfully.",
  resMessageTitle: "Thanks! Payment made",
  resRunningTitle: "Processing…",
  resRunningSubtitle: "The transaction has not finished yet",
  resDeniedTitle: "Payment Denied",
  resDeniedSubtitle: "The payment was not accepted, please check the information and try again",
  resMethod: "Paid with",
  resPrice: "Total amount",
  resReference: "Reference #",
  ruleEmail: "Check e-mail address",
  ruleMaxLength: "Maximum length is %0 characters",
  ruleNumeric: "Only numbers admitted in this field",
  ruleRequired: "Mandatory field",
  tspErrLoad1: "We found an unexpected error while loading this payment request.",
  tspErrLoad2: "Try refreshing the page in a few minutes.",
  tspErrLoad3:
    "If the problem persists check that the payment link is correct or contact the business that sent you the link.",
  tspErrLoadTitle: "Problem loading the request",
  tspErrProcessed1: "The transaction being attempted is already processed and can't be processed again.",
  tspErrProcessed2: "If you want to see the result press the button to be redirected to the result page.",
  tspErrProcessedButton: "Go to the result page",
  tspErrProcessedTitle: "Transaction processed previously",
  tspErrExpired1: "Transaction could not be processed because too much time passed since the process was started.",
  tspErrExpired2:
    "The payment can be retried from the store page. Press the button to be redirected to the result page.",
  tspErrExpiredButton: "Go to the result page",
  tspErrExpiredTitle: "Transaction expired",
  tspErrAutoRefresh: "(Page will be refreshed in %0 seconds)",
  tspErrAutoRedirect: "(You will be redirected in %0 seconds)",
  tspLoadingDesc: "Please wait a moment...",
  tspLoadingTitle: "Loading data",
  tspProcessingDesc: "Please wait a moment...",
  tspProcessingTitle: "Processing transaction",
  txnPanelTitle: "Card payment",
  resTokenSubtitle: "The card token was generated successfully.",
  resTokenTitle: "Thanks! Tokenization success.",
  txnTokenAmount: "Tokenization",

  // BIZUM
  txnOperationDetail: "OPERATION DETAILS",
  txnPaymentMethods: "PAYMENT METHODS",
  txnAvailableCards: "AVAILABLE CARDS",
  txnDeniedPayment: "The payment has been ",
  txnDenied: "denied",
  txnInvalidPhone: "The selected phone number is invalid.",
  txnInvalidPhoneLength: "Phone length is invalid.",
  txnRequiredField: "Required field.",
  txnPhone: "Phone",
  txnCard: "Card",
  txnPhoneExample: "Eg: ( + 34 ) 700 000 000",
  txnWaitingConfirmation: "Waiting for confirmation..",
  txnPaymentMethod: "PAYMENT METHOD"
};

export default values;
