/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import config from "../config";
import { getAnyProp } from "./object";

interface LogEntry {
  level: "error" | "info" | "warn";
  message: string;
  details?: any;
}

export async function postLogError(message: string, err: Error | null, details?: any) {
  const data = {
    ...(details || {}),
    ...errToJson(err)
  };
  return await postLog("error", message, data);
}

async function postLog(level: "error" | "info" | "warn", message: string, details?: any) {
  const data: any = details || {};
  data.host = window.location.host;
  data.url = window.location.href;
  const entry: LogEntry = {
    level,
    message,
    details: data
  };
  try {
    const response = await axios.post(config.logURL, entry);
    if (response.status !== 200) {
      console.error("Failed to post log " + response.statusText);
    }
  } catch (err) {
    console.error("Failed to post log", err);
    console.log(`[${level}] ${message}`, { details });
  }
}

function errToJson(errOrNull: any) {
  if (errOrNull === null || errOrNull === undefined) {
    return { exception: null };
  }
  try {
    const err = errOrNull;
    const attrs: any = {};
    // attrs.className = (err.constructor || "Unknown").toString();
    const props = ["code", "name", "message", "error", "statusCode", "userMessage"];
    for (const p of props) {
      if (err[p]) {
        attrs[p] = err[p];
      }
    }
    attrs.isServiceError = !!err.isServiceError;
    attrs.errType = "Error";
    if (err.isServiceError) {
      attrs.errType = "ServiceError";
    } else if (err.response) {
      attrs.errType = "ResponseError";
      attrs.uri = getAnyProp(err, "options", "uri") || getAnyProp(err, "options", "url");
      attrs.httpStatusCode = err.response.status;
      attrs.httpStatusText = err.response.statusText;
      attrs.httpDataError = getAnyProp(err.response, "data", "errorMessage");
      if (err.response.body) {
        if (err.response.body.error) {
          attrs.responseBodyErrCode = err.response.body.error.code;
          attrs.responseBodyErrMessage = err.response.body.error.message;
        } else {
          attrs.responseBody = JSON.stringify(err.response.body);
        }
      }
    }
    if (err.stack) {
      attrs.stack = JSON.stringify(err.stack);
    }
    return { exception: attrs };
  } catch (ex) {
    return { exception: { name: errOrNull.name, message: errOrNull.message, stack: errOrNull.stack } };
  }
}
