
import { Component, Vue } from "vue-property-decorator";
import { logError, logInfo, redirectToUrl, submitForm } from "@/util";
import { FullCardInfo, PrepareResponse } from "@/domain/types";

@Component
export default class TxnProcessForm extends Vue {
  prepareData: PrepareResponse | null = null;
  requestID = "";

  async startProcess(requestID: string, cardInfo: FullCardInfo | null) {
    logInfo("Process Form", requestID, cardInfo ? "with card details" : "card details on file");
    this.requestID = requestID;
    const resp = await this.$puceApp.backend.transactionPrepare(requestID, cardInfo);
    await this.setDataAndProcess(resp);
  }

  async setDataAndProcess(resp: PrepareResponse) {
    logInfo("TXN Process data", resp);
    this.prepareData = resp;
    return new Promise((resolve, reject) => {
      this.$nextTick(async () => {
        try {
          resolve(await this.processResult());
        } catch (err) {
          reject(err);
        }
      });
    });
  }

  async processResult() {
    if (!this.prepareData) throw new Error("Can't process transaction: Missing result");
    if (this.prepareData.challengeV1) {
      logInfo("Challenge V1");
      this.submitNextTick(this.$refs.formChallengeV1 as HTMLFormElement);
    } else if (this.prepareData.challengeV2) {
      logInfo("Challenge V2");
      this.submitNextTick(this.$refs.formChallengeV2 as HTMLFormElement);
    } else if (this.prepareData.exec3DSMethod) {
      logInfo("3DS Method execute");
      this.submitNextTick(this.$refs.form3DSMethod as HTMLFormElement);
      await this.doDelayedTxnResolve(10000, 2000); // 10 seconds to resolve transaction, waiting 2 seconds between tries
    } else if (this.prepareData.dccInfo) {
      logInfo("DCC info received");
      this.$emit("dccinfo", this.prepareData.dccInfo);
    } else if (this.prepareData.finished) {
      logInfo("Transaction Finished, redirecting:", this.prepareData.finished.redirectURL);
      redirectToUrl(this.prepareData.finished.redirectURL);
    } else {
      logError("Invalid result type", null, { requestID: this.requestID, prepareData: this.prepareData });
      throw new Error("Can't process transaction: Invalid result");
    }
  }

  submitNextTick(form: HTMLFormElement) {
    this.$nextTick(() => {
      submitForm(form, 500);
    });
  }

  async doDelayedTxnResolve(remainingTime: number, interval: number) {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        this.callTransactionResolve(remainingTime, interval)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            logError("Failed Transaction Resolve", err);
            reject(err);
          });
      }, interval);
    });
  }

  async callTransactionResolve(originalTime: number, interval: number) {
    const remainingTime = originalTime - interval;
    logInfo("Calling Transaction Resolve, remaining time = ", remainingTime);
    const isResolved = await this.resolveTransaction(this.requestID, remainingTime <= 0);
    if (!isResolved) {
      // 3DS method hasn't finished, so we wait 2 seconds
      await this.doDelayedTxnResolve(remainingTime, interval);
    }
  }

  async resolveTransaction(requestID: string, forceResolve: boolean, dccChoice?: "yes" | "no") {
    logInfo("Resolve Transaction", { requestID, forceResolve, dccChoice });
    this.requestID = requestID;
    const result = await this.$puceApp.backend.transactionResolve(this.requestID, forceResolve, dccChoice);
    if (result.isResolved) {
      logInfo("Transaction resolved", { result });
      await this.setDataAndProcess(result); // New result after 3DS method
      return true;
    }
    logInfo("Transaction NOT resolved", { result });
    return false;
  }
}
