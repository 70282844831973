
import { getStrings, LangKey } from "@/lib/language";
import { redirectToUrl } from "@/util";
import { Component, Vue, Prop } from "vue-property-decorator";

export type TxnStatusPanelID =
  | "hide"
  | "error"
  | "errorRetry"
  | "loading"
  | "processing"
  | "alreadyProcessed"
  | "expired";

@Component
export default class TxnStatusPanel extends Vue {
  @Prop({ default: "" }) langKey!: LangKey;
  @Prop({ default: false }) direct!: boolean;
  @Prop({ default: "" }) resultURL!: string;
  @Prop({ default: "primary" }) accentColor!: string;

  panelID: TxnStatusPanelID = "loading";
  timer: number | null = null;
  timerMs: number | null = null;

  get isWaiting() {
    return !!this.timerMs && this.timerMs > 0;
  }

  get waitingSeconds() {
    return this.timerMs ? (this.timerMs / 1000).toString() : "0";
  }

  get lang() {
    return getStrings(this.langKey);
  }

  get loadingIcon() {
    return this.direct ? "fa fa-hourglass-half" : "fas fa-credit-card";
  }

  setPanel(panelID?: TxnStatusPanelID | null) {
    this.panelID = panelID || "hide";
    this.$nextTick(() => {
      switch (this.panelID) {
        case "errorRetry":
          this.dispatchPageReload();
          break;
        case "expired":
          this.dispatchResultPage();
          break;
        case "alreadyProcessed":
          this.dispatchResultPage();
          break;
        default:
          this.cancelTimer();
          break;
      }
    });
  }

  dispatchResultPage() {
    if (this.resultURL) {
      this.startTimer(3000, () => {
        redirectToUrl(this.resultURL);
      });
    }
  }

  dispatchPageReload() {
    this.startTimer(10000, () => {
      window.location.reload();
    });
  }

  startTimer(delayMs: number, action: () => void) {
    this.cancelTimer();
    this.timerMs = delayMs;
    this.timer = setTimeout(() => {
      action();
      setTimeout(() => {
        this.cancelTimer(); // Reset variables
      }, 1000);
    }, delayMs);
  }

  cancelTimer() {
    if (this.timer != null) {
      clearTimeout(this.timer);
      this.timer = null;
      this.timerMs = null;
    }
  }
}
