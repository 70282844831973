import validator from "validator";
import * as dates from "./dates";
import dayjs from "dayjs";

export function isNull(value: unknown) {
  return value === null || value === undefined || value === "";
}

export function isEmpty(value: unknown): boolean {
  if (isNull(value)) {
    return true;
  }
  if (typeof value === "string") {
    return value.trim().length === 0;
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === "object") {
    return Object.keys(value || {}).length === 0;
  }
  if (typeof value === "number") {
    return value === 0;
  }
  return false;
}

export function isValidEmail(email: string): boolean {
  return !isEmpty(email) && validator.isEmail(email);
}

export type ValidationRule = (val: unknown) => boolean | string;

export function rulesRunner(vueComponent: {
  rules: ValidationRule[];
  value: unknown;
  hasError: boolean;
}): ValidationRule[] {
  return [
    (/*val: any*/) => {
      for (const r of vueComponent.rules || []) {
        const result = r(vueComponent.value);
        if (typeof result === "string") {
          vueComponent.hasError = true;
          return result;
        }
      }
      vueComponent.hasError = false;
      return true;
    }
  ];
}

export const rules = {
  email(message: string, optional?: boolean): ValidationRule {
    return (value: unknown) => {
      return (isEmpty(value) && optional) || (typeof value === "string" && validator.isEmail(value)) || message;
    };
  },

  phone(message: string, optional?: boolean): ValidationRule {
    return (value: unknown) => {
      return (isEmpty(value) && optional) || (typeof value === "string" && /^[0-9()+ -]+$/g.test(value)) || message;
    };
  },

  hexColor(message: string): ValidationRule {
    return (value: unknown) => {
      return (!isEmpty(value) && typeof value === "string" && validator.isHexColor(value)) || message;
    };
  },

  required(message: string): ValidationRule {
    return (value: unknown) => {
      return !isEmpty(value) || message;
    };
  },

  date(message: string, lowLimit?: dayjs.Dayjs): ValidationRule {
    return (value: unknown) => {
      return (
        (!isEmpty(value) &&
          typeof value === "string" &&
          dates.parseDate(value, "YYYY-MM-DD").isValid() &&
          (!lowLimit || (typeof value === "string" && dates.parseDate(value, "YYYY-MM-DD").isAfter(lowLimit)))) ||
        message
      );
    };
  },

  numeric(message: string, allowDecimales?: boolean, allowEmpty?: boolean): ValidationRule {
    return (value: unknown) => {
      return (
        (allowEmpty && isEmpty(value)) ||
        (!!value && typeof value === "string" && validator.isNumeric(value, { no_symbols: !allowDecimales })) ||
        message
      );
    };
  },

  length(message: string, min: number, max?: number): ValidationRule {
    return (value: unknown) => {
      return (
        (!value && min === 0) ||
        (!!value && typeof value === "string" && validator.isLength(value, { min, max })) ||
        message
      );
    };
  }
};
