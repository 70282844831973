import { LangData } from "../lang";

const values: LangData = {
  btnGoBack: "Retour",
  btnPay: "Paiement sécurisé ",
  cfTitleCardData: "Carte de crédit/débit",
  cfTitleExtraData: "Données de facturation",
  ciCardNameTitle: "Nom du titulaire",
  ciCardNumberTitle: "Numéro de carte",
  ciExpireLabel1: "jusqu'à",
  ciExpireLabel2: "",
  ciExpireTitle: "mois/année",
  ciSecurityCodeTitle: "Code de vérification",
  dccButtonSubmit: "Confirmer Paiement",
  dccFormMessage: "Veuillez choisir la devise que vous souhaitez utiliser :",
  dccPageTitle: "Sélection de Devise",
  dccRemaining: "La devise Euro sera automatiquement sélectionnée dans %1 secondes",
  errCardInvalid: "Numéro de carte invalide",
  errCardNameRequired: "Champ obligatoire",
  errCardRequired: "Saisissez le numéro de la carte",
  errExpirationInvalid: "La date d'expiration doit être MM / AA",
  errExpirationRequired: "La date d'expiration est nécessaire",
  errSecurityCodeInvalid: "Code invalide",
  errSecurityCodeRequired: "Numéro de sécurité nécessaire",
  fAddressLine1Label: "Adresse 1",
  fAddressLine1Placeholder: "Abedul 1234",
  fAddressLine2Label: "Adresse 2",
  fAddressLine2Placeholder: "Piso 1A",
  fAddressMatchShipping: "L'adresse de facturation est la même que l'adresse d'expédition ?",
  fBillingAddress: "Adresse de facturation",
  fCardNameLabel: "Nom du titulaire ",
  fCardNamePlaceholder: "-",
  fCityLabel: "Ville",
  fCityPlaceholder: "Pozuelo de Alarcón",
  fCountryLabel: "Pays",
  fCountryPlaceholder: "Spagne",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  fEmailLabel: "Courriel",
  fEmailPlaceholder: "nom@exemple.com",
  fExpireLabel: "MM / AA",
  fExpirePlaceholder: "__/__",
  fHomePhoneLabel: "Téléphone fixe",
  fMobilePhoneLabel: "Téléphone portable",
  fPANLabel: "Numéro de carte",
  fPANPlaceholder: "···· ···· ···· ····",
  fPhonePlaceholder: "",
  fPostCodeLabel: "Code Postal",
  fPostCodePlaceholder: "28223",
  fShippingAddress: "Adresse d'expédition",
  fStateLabel: "Province",
  fStatePlaceholder: "Madrid",
  fWorkPhoneLabel: "Téléphone professionnel",
  footerMessage: "utilise PAYTEF pour réaliser le traitement sécurisé de transactions.",
  footerPoweredBy: "Powered by PAYTEF",
  logoSimulationChip: "Simulation",
  resDate: "Date de paiement",
  resHCPMessage: "Message HCP",
  resMessageSubtitle: "Processus de paiement achevé.",
  resMessageTitle: "Merci ! Paiement réussi.",
  resRunningTitle: "Traitement…",
  resRunningSubtitle: "La transaction n'est pas encore terminée",
  resDeniedTitle: "Paiement refusé",
  resDeniedSubtitle: "Le paiement n'a pas été accepté, veuillez vérifier les informations et réessayer",
  resMethod: "Payé avec",
  resPrice: "Montant total",
  resReference: "Référence #",
  ruleEmail: "Vérifiez courriel",
  ruleMaxLength: "Longueur maximale %0 caractères",
  ruleNumeric: "Ce champ n'admet que des chiffres",
  ruleRequired: "Champ obligatoire",
  tspErrLoad1: "Une erreur inattendue s'est produite en chargeant la demande de paiement.",
  tspErrLoad2: "Essayez d'actualiser la page dans quelques minutes.",
  tspErrLoad3:
    "Si le problème persiste, vérifiez que le lien de paiement est correct ou contactez l'entreprise qui vous a envoyé le lien.",
  tspErrLoadTitle: "Problème en chargeant la demande",
  tspErrProcessed1: "La transaction en cours d'accès a déjà été traitée et s'est achevée.",
  tspErrProcessed2:
    "Si vous souhaitez voir le résultat, appuyez sur le bouton ci-dessous et vous serez redirigé vers la page de résultats.",
  tspErrProcessedButton: "Aller à la page de résultats",
  tspErrProcessedTitle: "Transaction traitée précédemment",
  tspErrExpired1: "La transaction n'a pas pu être traitée car trop de temps s'est écoulé depuis le début du processus.",
  tspErrExpired2:
    "Le paiement peut être retenté depuis la page du magasin. Appuyez sur le bouton pour être redirigé vers la page de résultats.",
  tspErrExpiredButton: "Allez à la page des résultats.",
  tspErrExpiredTitle: "Opération expirée.",
  tspLoadingDesc: "Veuillez patienter…",
  tspLoadingTitle: "Chargement des données",
  tspProcessingDesc: "Veuillez patienter...",
  tspProcessingTitle: "Transaction en cours",
  txnPanelTitle: "Paiement par carte",
  resTokenSubtitle: "Processus de tokenisation terminé.",
  resTokenTitle: "Merci! Tokénisation réussie.",
  txnTokenAmount: "Tokenisation"
};

export default values;
