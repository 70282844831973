import { LangData } from "../lang";

const values: LangData = {
  btnGoBack: "Zurück",
  btnPay: "Sicheres Bezahlen",
  cfTitleCardData: "Kredit-/Debitkarte",
  cfTitleExtraData: "Informationen zur Rechnungsstellung",
  ciCardNameTitle: "Vollständiger Name",
  ciCardNumberTitle: "Kartennummer",
  ciExpireLabel1: "gültig bis",
  ciExpireLabel2: "",
  ciExpireTitle: "Monat/Jahr",
  ciSecurityCodeTitle: "Sicherer Prüfcode",
  dccButtonSubmit: "Zahlung bestätigen",
  dccFormMessage: "Bitte wählen Sie aus, wie Sie diesen Geschäftsvorgang abschließen möchten:",
  dccPageTitle: "Zahlungswährung",
  dccRemaining: "Euro werden automatisch in %1 Sek. ausgewählt",
  errCardInvalid: "Die Kartennummer ist ungültig oder nicht kompatibel",
  errCardNameRequired: "Die Namensangabe ist erforderlich.",
  errCardRequired: "Bitte die Kartennummer eingeben.",
  errExpirationInvalid: "Das Ablaufdatum muss im Format MM/JJ eingegeben werden.",
  errExpirationRequired: "Das Ablaufdatum ist erforderlich.",
  errSecurityCodeInvalid: "Ungültiger Code",
  errSecurityCodeRequired: "Der sichere Prüfcode ist erforderlich.",
  fAddressLine1Label: "Adresse 1",
  fAddressLine1Placeholder: "Abedul 1234",
  fAddressLine2Label: "Adresse 2",
  fAddressLine2Placeholder: "Etage 1A",
  fAddressMatchShipping: "Ist die Rechnungsadresse gleich der Lieferadresse?",
  fBillingAddress: "Rechnungsadresse",
  fCardNameLabel: "Name des Karteninhabers",
  fCardNamePlaceholder: "-",
  fCityLabel: "Stadt",
  fCityPlaceholder: "Pozuelo de Alarcón",
  fCountryLabel: "Land",
  fCountryPlaceholder: "Spanien",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  fEmailLabel: "E-Mail des Karteninhabers",
  fEmailPlaceholder: "name@Beispiel.com",
  fExpireLabel: "MM / JJ",
  fExpirePlaceholder: "__/__",
  fHomePhoneLabel: "Festnetz",
  fMobilePhoneLabel: "Mobiltelefon",
  fPANLabel: "Kartennummer",
  fPANPlaceholder: "···· ···· ···· ····",
  fPhonePlaceholder: "+34...",
  fPostCodeLabel: "PLZ",
  fPostCodePlaceholder: "28223",
  fShippingAddress: "Lieferadresse",
  fStateLabel: "Autonome Provinz",
  fStatePlaceholder: "Madrid",
  fWorkPhoneLabel: "Büronummer",
  footerMessage: "Verwenden Sie PAYTEF für eine sichere Zahlungsabwicklung!",
  footerPoweredBy: "Unterstützt von PAYTEF",
  logoSimulationChip: "Simulation",
  resDate: "Zahlungsdatum",
  resHCPMessage: "HCP-Nachricht",
  resMessageTitle: "Vielen Dank! Die Zahlung wurde erfolgreich durchgeführt. ",
  resMessageSubtitle: "Der Zahlungsvorgang wurde erfolgreich abgeschlossen.",
  resRunningTitle: "Wird bearbeitet…",
  resRunningSubtitle: "Die Transaktion ist noch nicht abgeschlossen",
  resDeniedTitle: "Zahlung verweigert",
  resDeniedSubtitle:
    "Die Zahlung wurde nicht akzeptiert. Bitte überprüfen Sie die Informationen und versuchen Sie es erneut",
  resMethod: "Bezahlt mit",
  resPrice: "Gesamtsumme",
  resReference: "Referenznummer",
  ruleEmail: "E-Mail-Adresse prüfen",
  ruleMaxLength: "Maximale Länge %0 Zeichen",
  ruleNumeric: "Ausschließlich Zahlen möglich",
  ruleRequired: "Pflichtfeld",
  tspErrLoad1: "Beim Laden dieser Zahlungsaufforderung ist ein unerwarteter Fehler aufgetreten.",
  tspErrLoad2: "Versuchen Sie, die Seite in einigen Minuten zu aktualisieren.",
  tspErrLoad3:
    "Sollte das Problem weiterhin bestehen, überprüfen Sie, ob der Zahlungslink richtig ist oder wenden Sie sich an das Unternehmen, von dem Sie den Link erhalten haben.",
  tspErrLoadTitle: "Problem beim Laden der Anfrage",
  tspErrProcessed1: "Der aufgerufene Vorgang ist bereits bearbeitet und kann nicht erneut durchgeführt werden.",
  tspErrProcessed2:
    "Wenn Sie das Ergebnis ansehen möchten, klicken Sie auf die Schaltfläche. Sie werden dann zur Ergebnisseite weitergeleitet.",
  tspErrProcessedButton: "Auf die Ergebnisseite gehen",
  tspErrProcessedTitle: "Der Vorgang wurde bereits bearbeitet",
  tspErrExpired1:
    "Die transaktion konnte nicht verarbeitet werden, da seit dem Start des prozesses zu viel zeit vergangen ist.",
  tspErrExpired2:
    "Die zahlung kann von der store-seite aus erneut versucht werden. Drücken sie die schaltfläche, um zur ergebnisseite weitergeleitet zu werden.",
  tspErrExpiredButton: "Gehen sie zur ergebnisseite.",
  tspErrExpiredTitle: "Abgelaufene transaktion.",
  tspLoadingDesc: "Bitte warten ",
  tspLoadingTitle: "Die Daten werden geladen",
  tspProcessingDesc: "Bitte warten ",
  tspProcessingTitle: "Der Vorgang wird bearbeitet",
  txnPanelTitle: "Zahlung mit Karte",
  resTokenSubtitle: "Tokenisierungsprozess abgeschlossen.",
  resTokenTitle: "Danke! Tokenisierungserfolg.",
  txnTokenAmount: "Tokenisierung"
};

export default values;
