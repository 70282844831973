
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as lang from "@/lib/language";
import { PayCardManager } from "./internal/payCardManager";
import { KeypressEvent, PayCardInfo } from "./internal/types";
import PayCardImage from "./PayCardImage.vue";

export type CardNameFieldMode = "hidden" | "optional" | "mandatory" | "readOnly";

@Component({ components: { PayCardImage } })
export default class PayCardForm extends Vue {
  @Prop({ default: "primary" }) color!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ required: true }) langKey!: lang.LangKey;
  @Prop({ default: "disabled" }) nameFieldMode!: CardNameFieldMode;
  @Prop({ default: null }) value!: PayCardInfo | null;
  cardInfo: PayCardInfo = {
    cardName: "",
    cardNumber: "",
    cardExpiry: "",
    cardCvv: ""
  };

  get lang() {
    return lang.getStrings(this.langKey);
  }
  get cardManager() {
    return new PayCardManager(this.lang, this.nameFieldMode !== "hidden", this.nameFieldMode == "mandatory");
  }
  get rules() {
    return this.cardManager.validationRules();
  }
  get fCardName() {
    return this.cardManager.cardName();
  }
  get fPAN() {
    return this.cardManager.pan(this.cardInfo.cardNumber);
  }
  get fExpiry() {
    return this.cardManager.expiry();
  }
  get fCVV() {
    return this.cardManager.cvv();
  }
  get cardBrand() {
    return this.cardManager.getTheme(this.fPAN.brand);
  }

  mounted() {
    if (this.value) {
      this.cardInfo = { ...this.cardInfo, ...this.value };
    }
  }

  @Watch("cardInfo", { deep: true })
  onCardInfoChanged() {
    this.$emit("input", {
      ...this.cardInfo
    });
  }

  setFlipped(value: boolean) {
    (this.$refs.cardImage as PayCardImage)?.setFlipped(value);
  }

  isNumber(cevt?: KeypressEvent) {
    const evt = (cevt ? cevt : window.event) as KeypressEvent;
    if (!evt) return undefined;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
    return undefined;
  }
}
