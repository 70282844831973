
import { Component, Vue } from "vue-property-decorator";
import * as lang from "@/lib/language";

@Component
export default class TestView extends Vue {
  onExport() {
    const langs = lang.getLanguages().sort((first, second) => 0 - (first > second ? -1 : 1));
    const attribs = Object.keys(lang.getStrings("EN")).sort((first, second) => 0 - (first > second ? -1 : 1));
    let content = '"Name"';
    for (const langKey of langs) {
      content += ',"' + langKey + '"';
    }
    content += "\n";
    for (const attr of attribs) {
      content += '"' + attr + '"';
      for (const langKey of langs) {
        content += ',"' + (lang.getStrings(langKey, true)[attr] || "") + '"';
      }
      content += "\n";
    }

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(content));
    element.setAttribute("download", "language.csv");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
