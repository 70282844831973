import { LangData } from "../lang";

const values: LangData = {
  btnGoBack: "Volver",
  btnPay: "Pago Seguro",
  cfTitleCardData: "Tarjeta de crédito/débito",
  cfTitleExtraData: "Datos de facturación",
  ciCardNameTitle: "Nombre del titular",
  ciCardNumberTitle: "Número de tarjeta",
  ciExpireLabel1: "hasta",
  ciExpireLabel2: "",
  ciExpireTitle: "mes/año",
  ciSecurityCodeTitle: "Cód. Verificación",
  dccButtonSubmit: "Confirmar Pago",
  dccFormMessage: "Por favor, elija la moneda que desea utilizar:",
  dccPageTitle: "Selección de Moneda",
  dccRemaining: "Se seleccionará Euros automáticamente en %1 segundos",
  errCardInvalid: "Número de tarjeta inválido",
  errCardNameRequired: "Campo obligatorio",
  errCardRequired: "Ingrese el número de tarjeta",
  errExpirationInvalid: "La expiración debe ser MM / AA",
  errExpirationRequired: "Fecha de expiración necesaria",
  errSecurityCodeInvalid: "Código inválido",
  errSecurityCodeRequired: "Número de seguridad necesario",
  fAddressLine1Label: "Dirección 1",
  fAddressLine1Placeholder: "Abedul 1234",
  fAddressLine2Label: "Dirección 2",
  fAddressLine2Placeholder: "Piso 1A",
  fAddressMatchShipping: "¿La dirección de facturación es igual a la dirección de envío?",
  fBillingAddress: "Dirección de facturación",
  fCardNameLabel: "Nombre del titular",
  fCardNamePlaceholder: "-",
  fCityLabel: "Ciudad",
  fCityPlaceholder: "Pozuelo de Alarcón",
  fCountryLabel: "País",
  fCountryPlaceholder: "España",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  fEmailLabel: "E-mail",
  fEmailPlaceholder: "nombre@ejemplo.com",
  fExpireLabel: "MM / AA",
  fExpirePlaceholder: "__/__",
  fHomePhoneLabel: "Teléfono fijo",
  fMobilePhoneLabel: "Teléfono móvil",
  fPANLabel: "Número de tarjeta",
  fPANPlaceholder: "···· ···· ···· ····",
  fPhonePlaceholder: "+34...",
  fPostCodeLabel: "Cod. Postal",
  fPostCodePlaceholder: "28223",
  fShippingAddress: "Dirección de envío",
  fStateLabel: "Provincia",
  fStatePlaceholder: "Madrid",
  fWorkPhoneLabel: "Teléfono laboral",
  footerMessage: "utiliza PAYTEF para realizar el procesamiento seguro de transacciones.",
  footerPoweredBy: "Powered by PAYTEF",
  logoSimulationChip: "Simulación",
  resDate: "Fecha de pago",
  resHCPMessage: "Mensaje HCP",
  resMessageSubtitle: "Finalizado el proceso de pago.",
  resMessageTitle: "¡Gracias! Pago exitoso.",
  resRunningTitle: "Procesando…",
  resRunningSubtitle: "La trasacción todavía no finalizó",
  resDeniedTitle: "Pago Denegado",
  resDeniedSubtitle: "El pago no fue aceptado, verifique los datos y pruebe nuevamente",
  resMethod: "Pagó con",
  resPrice: "Importe total",
  resReference: "Referencia #",
  ruleEmail: "Verifique e-mail",
  ruleMaxLength: "Largo máximo %0 caracteres",
  ruleNumeric: "Este campo solo admite números",
  ruleRequired: "Campo obligatorio",
  tspErrLoad1: "Encontramos un error inesperado mientras cargábamos la solicitud de pago.",
  tspErrLoad2: "Pruebe a refrescar la página en unos minutos.",
  tspErrLoad3:
    "En caso de que el problema persista revisa que el enlace de pago sea correcto o contacta con el comercio que te lo envió.",
  tspErrLoadTitle: "Problema cargando solicitud",
  tspErrProcessed1: "La transacción a la que esta intentando ya fue procesada con anterioridad y finalizó.",
  tspErrProcessed2:
    "Si desea ver el resultado presione el botón a continuación y será redirigido a la página de resultado.",
  tspErrProcessedButton: "Ir a página de resultado",
  tspErrProcessedTitle: "Transacción ya procesada con anterioridad",
  tspErrExpired1: "No se pudo procesar la transacción porque pasó demasiado tiempo desde que se inició el proceso.",
  tspErrExpired2:
    "El pago se puede reintentar desde la página de la tienda. Presione el botón para ser redirigido a la página de resultados.",
  tspErrExpiredButton: "Ir a la página de resultados",
  tspErrExpiredTitle: "Transacción caducada",
  tspErrAutoRefresh: "(Se recargará la página en %0 seconds)",
  tspErrAutoRedirect: "(Serás redireccionado al resultado en %0 segundos)",
  tspLoadingDesc: "Espere un momento...",
  tspLoadingTitle: "Cargando datos",
  tspProcessingDesc: "Espere un momento...",
  tspProcessingTitle: "Procesando operación",
  txnPanelTitle: "Pago con tarjeta",
  resTokenSubtitle: "Finalizado el proceso de tokenización.",
  resTokenTitle: "¡Gracias! Tokenización exitosa.",
  txnTokenAmount: "Tokenización",

  // BIZUM
  txnOperationDetail: "DETALLE DE LA OPERACIÓN",
  txnPaymentMethods: "MEDIOS DE PAGO",
  txnAvailableCards: "TARJETAS DISPONIBLES",
  txnDeniedPayment: "El pago ha sido",
  txnDenied: "denegado",
  txnInvalidPhone: "El teléfono seleccionado no es valido.",
  txnInvalidPhoneLength: "La longitud del teléfono seleccionado no es valida.",
  txnRequiredField: "Campo obligatorio.",
  txnPhone: "Teléfono",
  txnCard: "Tarjeta",
  txnPhoneExample: "Ej: ( + 34 ) 700 000 000",
  txnWaitingConfirmation: "Esperando confirmación del pago..",
  txnPaymentMethod: "MEDIO DE PAGO"
};

export default values;
