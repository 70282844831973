export * from "./internal/types";

import { getCardBrandTheme, getCardCodeTheme } from "./internal/cardBrand";
import { brandDetails } from "./internal/constants";
import { PaytefCardTypeCode } from "./internal/types";

export const paytefCardTypeCodes = ["AX", "VI", "DC", "JC", "MA", "MC", "UP"];

export function getPaytefCardCodeInfo(strCardType?: PaytefCardTypeCode | string) {
  if (!strCardType) return null;
  const cardType = strCardType as PaytefCardTypeCode;
  let details = brandDetails.find((i) => i.paytefCode === cardType);
  if (!details) details = brandDetails[brandDetails.length - 1];
  const theme2 = strCardType === "DC" ? getCardBrandTheme("discover") : null;
  return {
    details,
    theme: getCardCodeTheme(cardType),
    theme2
  };
}
