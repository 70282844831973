import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/src/locale/es";
import VueTheMask from "vue-the-mask";

Vue.use(VueTheMask);

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        /*primary: "#0A67A3",*/
        /*secondary: "#424242",*/
        primary: "#BBBBBB",
        accent: "#FF8E00",
        info: "#1890ff",
        success: "#4CAF50",
        warning: "#FFC107",
        error: "#f55a4e"
      }
    }
  },
  lang: {
    locales: { es },
    current: "es"
  },
  icons: {
    iconfont: "fa"
  }
});

import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

Vue.use(VueTelInputVuetify, {
  vuetify
});

export default vuetify;
