
import * as lang from "@/lib/language";
import { Component, Prop, Vue } from "vue-property-decorator";
import { CardBrandTheme } from "./internal/types";

@Component
export default class PayCardImage extends Vue {
  @Prop({ required: true }) langKey!: lang.LangKey;
  @Prop({ default: "" }) cardName!: string;
  @Prop({ default: "" }) cardNumber!: string;
  @Prop({ default: "" }) cardExpiry!: string;
  @Prop({ default: "" }) cardCvv!: string;
  @Prop({ default: { color: "grey", ccicon: "", ccsingle: "" } }) cardBrand!: CardBrandTheme;

  flipped = false;

  get lang() {
    return lang.getStrings(this.langKey);
  }

  get svgName() {
    if (!this.cardName) return this.lang.fCardNamePlaceholder;
    return this.cardName;
  }

  get svgCardNumber() {
    if (!this.cardNumber) return this.lang.fPANPlaceholder;
    return this.cardNumber;
  }

  get svgExpire() {
    if (!this.cardExpiry) return this.lang.fExpirePlaceholder;
    return this.cardExpiry;
  }

  get svgCVV() {
    if (!this.cardCvv) return this.lang.fCvvPlaceholder;
    return this.cardCvv;
  }

  setFlipped(value: boolean) {
    this.flipped = value;
  }
}
