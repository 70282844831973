import { LangData } from "../lang";

const values: LangData = {
  btnGoBack: "Torna",
  btnPay: "Pagamento sicuro",
  cfTitleCardData: "Carta di credito/debito",
  cfTitleExtraData: "Dati di fatturazione",
  ciCardNameTitle: "Nome del titolare",
  ciCardNumberTitle: "Numero carta",
  ciExpireLabel1: "fino a",
  ciExpireLabel2: "",
  ciExpireTitle: "mese/anno",
  ciSecurityCodeTitle: "Codice di verifica",
  dccButtonSubmit: "Conferma pagamento",
  dccFormMessage: "Scegli la valuta da utilizzare:",
  dccPageTitle: "Selezione valuta",
  dccRemaining: "Verrà selezionato automaticamente l'euro tra %1 secondi",
  errCardInvalid: "Numero carta non valido",
  errCardNameRequired: "Campo obbligatorio",
  errCardRequired: "Immetti il numero della carta",
  errExpirationInvalid: "La scadenza deve essere MM / AA",
  errExpirationRequired: "Data di scadenza richiesta",
  errSecurityCodeInvalid: "Codice non valido",
  errSecurityCodeRequired: "Numero di sicurezza richiesto",
  fAddressLine1Label: "Indirizzo 1",
  fAddressLine1Placeholder: "Abedul 1234",
  fAddressLine2Label: "Indirizzo 2",
  fAddressLine2Placeholder: "Piano 1A",
  fAddressMatchShipping: "L'indirizzo di fatturazione è uguale all'indirizzo di spedizione?",
  fBillingAddress: "Indirizzo di fatturazione",
  fCardNameLabel: "Nome del titolare",
  fCardNamePlaceholder: "-",
  fCityLabel: "Città",
  fCityPlaceholder: "Pozuelo de Alarcón",
  fCountryLabel: "Paese",
  fCountryPlaceholder: "Spagna",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  fEmailLabel: "E-mail",
  fEmailPlaceholder: "nome@esempio.com",
  fExpireLabel: "MM / AA",
  fExpirePlaceholder: "__/__",
  fHomePhoneLabel: "Telefono fisso",
  fMobilePhoneLabel: "Cellulare",
  fPANLabel: "Numero carta",
  fPANPlaceholder: "···· ···· ···· ····",
  fPhonePlaceholder: "+34...",
  fPostCodeLabel: "CAP",
  fPostCodePlaceholder: "28223",
  fShippingAddress: "Indirizzo di spedizione",
  fStateLabel: "Provincia",
  fStatePlaceholder: "Madrid",
  fWorkPhoneLabel: "Telefono ufficio",
  footerMessage: "utilizza PAYTEF per l'elaborazione sicura delle operazioni.",
  footerPoweredBy: "Powered by PAYTEF",
  logoSimulationChip: "Simulazione",
  resDate: "Data di pagamento",
  resHCPMessage: "Messaggio HCP",
  resMessageSubtitle: "Procedura di pagamento completata",
  resMessageTitle: "Grazie! Pago effettuato correttamente.",
  resRunningTitle: "in lavorazione…",
  resRunningSubtitle: "La transazione non è ancora terminata",
  resDeniedTitle: "Pagamento negato",
  resDeniedSubtitle: "Il pagamento non è stato accettato, controlla le informazioni e riprova",
  resMethod: "Hai pagato con",
  resPrice: "Importo totale",
  resReference: "Riferimento #",
  ruleEmail: "Verifica e-mail",
  ruleMaxLength: "Lunghezza massima %0 caratteri",
  ruleNumeric: "Questo campo ammette solo numeri",
  ruleRequired: "Campo obbligatorio",
  tspErrLoad1: "Si è verificato un errore imprevisto durante il caricamento della richiesta di pagamento.",
  tspErrLoad2: "Prova ad aggiornare la pagina tra qualche minuto.",
  tspErrLoad3:
    "Se il problema persiste, controlla che il link di pagamento sia corretto o contatta l'azienda che te lo ha inviato.",
  tspErrLoadTitle: "Si sono verificati problemi durante il caricamento della richiesta",
  tspErrProcessed1: "L'operazione che stai tentando di fare è già stata effettuata e completata.",
  tspErrProcessed2:
    "Per vedere il risultato premi il pulsante qui sotto e verrai reindirizzato alla pagina dei risultati.",
  tspErrProcessedButton: "Vai alla pagina dei risultati",
  tspErrProcessedTitle: "Operazione già effettuata in precedenza",
  tspErrExpired1:
    "Non è stato possibile elaborare la transazione perché è trascorso troppo tempo dall'inizio del processo",
  tspErrExpired2:
    "Il pagamento può essere ritentato dalla pagina del negozio. Premi il pulsante per essere reindirizzato alla pagina dei risultati.",
  tspErrExpiredButton: "Vai alla pagina dei risultati.",
  tspErrExpiredTitle: "Transazione scaduta.",
  tspLoadingDesc: "Attendi un attimo...",
  tspLoadingTitle: "Caricamento dati in corso",
  tspProcessingDesc: "Attendi un attimo...",
  tspProcessingTitle: "Operazione in corso",
  txnPanelTitle: "Pagamento con carta",
  resTokenSubtitle: "Completato il processo di tokenizzazione.",
  resTokenTitle: "Grazie! tokenizzazione riuscita.",
  txnTokenAmount: "Tokenizzazione"
};

export default values;
