import { toBool } from "./convert";
import { Route } from "vue-router";
import { postLogError } from "./log";

export function isLocalhost() {
  return window.location && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1");
}

let lIsDebug = isLocalhost();

export function isDevSite() {
  const firstCharacters = "paytef-dev-";
  return window.location && window.location.hostname.startsWith(firstCharacters);
}

export function isDebug() {
  return lIsDebug;
}

export function setDebug(val: boolean) {
  lIsDebug = val;
}

export function logInfo(...params: unknown[]) {
  if (lIsDebug) {
    // tslint:disable-next-line:no-console
    console.log(...params);
  }
}

export function logError(message: string, err: Error | null, data?: unknown) {
  postLogError(message, err, data).catch((logErr) => {
    console.error("Failed to post log", logErr);
    // tslint:disable-next-line:no-console
    console.error(message, err, { data });
  });
}

export function checkRouteDebug(route: Route) {
  if (isLocalhost()) {
    setDebug(true);
  } else if (route.query && toBool(route.query.debug as string)) {
    setDebug(true);
  }
}

export function promiseEnd(p: Promise<unknown>) {
  p.catch((err) => logError("Unhandled promise error", err));
}

window.onerror = function (message, file, line, col, error) {
  logError(`window.onerror ${message} , File: ${file}, Line: ${line}, Col: ${col}`, error as Error);
  return false;
};
window.addEventListener("unhandledrejection", function (e) {
  try {
    if (e.reason instanceof Error) {
      logError("unhandledrejection", e.reason);
    } else {
      logError("unhandledrejection " + JSON.stringify(e.reason), null);
    }
  } catch (e) {
    console.error("Error logging rejection", e);
  }
});
