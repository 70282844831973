
import { Component, Vue, Prop } from "vue-property-decorator";
import { getStrings, LangKey } from "@/lib/language";
import { MerchantInfo } from "@/domain/types";
import { getPrimaryColor } from "@/application";

@Component
export default class MerchantLogo extends Vue {
  @Prop({ default: "" }) langKey!: LangKey;
  @Prop({ default: null }) merchant!: MerchantInfo | null;
  @Prop({ default: false }) isSimulated!: boolean;

  get accentColor(): string {
    return getPrimaryColor(this.merchant);
  }

  get lang() {
    return getStrings(this.langKey);
  }
}
