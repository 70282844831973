import { LangData } from "../lang";

const values: LangData = {
  btnGoBack: "Retorna",
  btnPay: "Pagamento Seguro",
  cfTitleCardData: "Cartão de credito/debito",
  cfTitleExtraData: "Dados de facturação",
  ciCardNameTitle: "Nome do titular",
  ciCardNumberTitle: "Número do cartão",
  ciExpireLabel1: "até",
  ciExpireLabel2: "",
  ciExpireTitle: "mês/ano",
  ciSecurityCodeTitle: "Cód.verificação",
  dccButtonSubmit: "Confirme o pagamento",
  dccFormMessage: "Escolha a moeda que deseja usar",
  dccPageTitle: "Seleção de moeda",
  dccRemaining: "Euros serão selecionados automaticamente em %1 segundos",
  errCardInvalid: "Número do cartão inválido",
  errCardNameRequired: "Campo obrigatório",
  errCardRequired: "Digite o número do cartão",
  errExpirationInvalid: "A expiração deve ser MM / AA",
  errExpirationRequired: "Data de expiração necessária",
  errSecurityCodeInvalid: "Código inválido",
  errSecurityCodeRequired: "Número de segurança obrigatório",
  fAddressLine1Label: "Endereço 1",
  fAddressLine1Placeholder: "Abedul 1234",
  fAddressLine2Label: "Endereço 2",
  fAddressLine2Placeholder: "Andar 1A",
  fAddressMatchShipping: "O endereço de cobrança é igual ao endereço de entrega?",
  fBillingAddress: "Endereço da cobrança",
  fCardNameLabel: "Nome do titular",
  fCardNamePlaceholder: "-",
  fCityLabel: "Cidade",
  fCityPlaceholder: "Pozuelo de Alarcón",
  fCountryLabel: "País",
  fCountryPlaceholder: "Espanha",
  fCvvLabel: "CVV / CVC",
  fCvvPlaceholder: "XXX",
  fEmailLabel: "E-mail",
  fEmailPlaceholder: "nome@exemplo.com",
  fExpireLabel: "MM / AA",
  fExpirePlaceholder: "__/__",
  fHomePhoneLabel: "Telefone fixo",
  fMobilePhoneLabel: "Telefone celular",
  fPANLabel: "Número do cartão",
  fPANPlaceholder: "···· ···· ···· ····",
  fPhonePlaceholder: "+34...",
  fPostCodeLabel: "Cod. Postal",
  fPostCodePlaceholder: "28223",
  fShippingAddress: "Endereço da entrega",
  fStateLabel: "Provincia",
  fStatePlaceholder: "Madrid",
  fWorkPhoneLabel: "Telefone de trabalho",
  footerMessage: "usa PAYTEF para processamento seguro de transações.",
  footerPoweredBy: "Powered by PAYTEF",
  logoSimulationChip: "Simulação",
  resDate: "Data do pagamento",
  resHCPMessage: "Mensagem HCP",
  resMessageSubtitle: "Concluído o processo de pagamento.",
  resMessageTitle: "Obrigado! Pagamento bem sucedido.",
  resRunningTitle: "Em processamento…",
  resRunningSubtitle: "A transação ainda não terminou",
  resDeniedTitle: "Pagamento Negado",
  resDeniedSubtitle: "O pagamento não foi aceito, verifique as informações e tente novamente",
  resMethod: "Você pagou com",
  resPrice: "Total",
  resReference: "Referência #",
  ruleEmail: "Verificar email",
  ruleMaxLength: "Comprimento máximo %0 caracteres",
  ruleNumeric: "Este campo suporta apenas números",
  ruleRequired: "Campo obrigatório",
  tspErrLoad1: "Encontramos um erro inesperado ao carregar a solicitação de pagamento.",
  tspErrLoad2: "Tente atualizar a página em alguns minutos.",
  tspErrLoad3:
    "Caso o problema persista, verifique se o link de pagamento está correto ou entre em contato com o estabelecimento que o enviou.",
  tspErrLoadTitle: "Problema ao carregar o pedido",
  tspErrProcessed1: "A transação que você está tentando realizar foi processada e concluída anteriormente.",
  tspErrProcessed2:
    "Se você quiser ver o resultado pressione o botão abaixo e você será redirecionado para a página de resultados.",
  tspErrProcessedButton: "Vá para a página de resultados",
  tspErrProcessedTitle: "Transação já processada anteriormente",
  tspErrExpired1: "A transação não pôde ser processada porque se passou muito tempo desde o início do processo.",
  tspErrExpired2:
    "O pagamento pode ser repetido na página da loja. Pressione o botão para ser redirecionado para a página de resultados.",
  tspErrExpiredButton: "Vá para a página de resultados.",
  tspErrExpiredTitle: "Transação expirada.",
  tspLoadingDesc: "Espere um momento...",
  tspLoadingTitle: "Carregando dados",
  tspProcessingDesc: "Espere um momento...",
  tspProcessingTitle: "Operação de processamento",
  txnPanelTitle: "Pagamento com cartão",
  resTokenSubtitle: "Concluído o processo de tokenização.",
  resTokenTitle: "Obrigado! tokenização bem-sucedida.",
  txnTokenAmount: "Tokenização"
};

export default values;
