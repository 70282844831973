
import { DCCInformation, TransactionInfoResponse } from "@/domain/types";
import { getStrings, LangKey } from "@/lib/language";
import { logError, logInfo, moneyToString } from "@/util";
import { Component, Vue, Prop } from "vue-property-decorator";
import PoweredBy from "./PoweredBy.vue";
import MerchantLogo from "./MerchantLogo.vue";
import { TxnView, getPrimaryColor } from "@/application";

@Component({ components: { PoweredBy, MerchantLogo } })
export default class DccChoice extends Vue {
  @Prop({ default: "" }) langKey!: LangKey;
  @Prop({ default: null }) transaction!: TransactionInfoResponse | null;
  @Prop({ default: null }) dcc!: DCCInformation | null;

  loading = false;
  dccChoice: "yes" | "no" = "no";

  autoConfirmSeconds = 50;
  autoConfirmTimeout = 0;

  created() {
    this.tickTimeout();
  }

  get lang() {
    return getStrings(this.langKey);
  }

  get localAmount(): string {
    return moneyToString(this.dcc?.amount, this.dcc?.currency);
  }

  get txnAmount(): string {
    return moneyToString(this.transaction?.amount, this.transaction?.currency);
  }

  get accentColor(): string {
    return getPrimaryColor(this.transaction?.merchant);
  }

  async onConfirmClicked() {
    if (!this.dccChoice || !this.transaction || this.loading) {
      return;
    }
    try {
      this.loading = true;
      const processForm = (this.$parent as TxnView).txnProcessForm();
      await processForm.resolveTransaction(this.transaction.requestID, true, this.dccChoice);
    } catch (err) {
      logError(`DCC choice failed '${this.transaction.requestID}'`, err as Error, {
        requestID: this.transaction.requestID
      });
    }
    this.loading = false;
  }

  postNextTick() {
    return setTimeout(() => {
      this.tickTimeout();
    }, 1000);
  }

  async tickTimeout() {
    if (this.autoConfirmSeconds > 0) {
      this.autoConfirmSeconds = this.autoConfirmSeconds - 1;
      if (this.autoConfirmSeconds > 0) {
        logInfo("Waiting 1 second...");
        this.autoConfirmTimeout = this.postNextTick();
      } else {
        clearTimeout(this.autoConfirmTimeout);
        this.autoConfirmTimeout = 0;
        this.dccChoice = "no";
        await this.onConfirmClicked();
      }
    } else {
      this.autoConfirmSeconds = 0;
    }
  }
}
