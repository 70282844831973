
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as lang from "@/lib/language";
import { searchCountry } from "@/lib/countries";
import { Address, CardFormField, FullCardInfo, PublicCardholderDetails } from "@/domain/types";
import { CustomFieldConfig, CustomFieldType } from "./inputs";
import CustomField from "./inputs/CustomField.vue";
import PayCardForm, { CardNameFieldMode } from "./payment/PayCardForm.vue";
import { PayCardInfo } from "./payment";

@Component({
  components: { CustomField, PayCardForm }
})
export default class CardForm extends Vue {
  @Prop() value!: FullCardInfo;
  @Prop({ default: "ES" }) langKey!: lang.LangKey;
  @Prop() fields!: CardFormField[];
  @Prop({ default: "" }) backgroundStyle!: string;
  @Prop({ default: "primary" }) accentColor!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: () => ({}) }) defaults!: Partial<PublicCardholderDetails>;
  cardNameFieldMode: CardNameFieldMode = "hidden";
  billingAddressEnabled = false;

  detailFields = {
    //cardholderName: this.makeField("CardholderName", "string", "Nombre en la tarjeta"),
    email: this.makeField("email", "email", this.lang.fEmailLabel, this.lang.fEmailPlaceholder),
    homePhone: this.makeField("homePhone", "phone", this.lang.fHomePhoneLabel, this.lang.fPhonePlaceholder),
    mobilePhone: this.makeField("mobilePhone", "phone", this.lang.fMobilePhoneLabel, this.lang.fPhonePlaceholder),
    workPhone: this.makeField("workPhone", "phone", this.lang.fWorkPhoneLabel, this.lang.fPhonePlaceholder),
    billingAddressMatch: this.makeField("billingAddressMatchShipping", "checkbox", this.lang.fAddressMatchShipping),
    billingAddress: [
      this.makeField("addressLine1", "string", this.lang.fAddressLine1Label, this.lang.fAddressLine1Placeholder),
      this.makeField("addressLine2", "string", this.lang.fAddressLine2Label, this.lang.fAddressLine2Placeholder),
      this.makeField("city", "string", this.lang.fCityLabel, this.lang.fCityPlaceholder),
      this.makeField("state", "string", this.lang.fStateLabel, this.lang.fStatePlaceholder),
      this.makeField("postCode", "string", this.lang.fPostCodeLabel, this.lang.fPostCodePlaceholder),
      this.makeField("country", "country_iso3_num", this.lang.fCountryLabel, this.lang.fCountryPlaceholder)
    ]
  };

  payCardInfo: PayCardInfo = {
    cardName: "",
    cardNumber: "",
    cardExpiry: "",
    cardCvv: ""
  };

  workingInfo: FullCardInfo = {
    details: {
      cvv: "",
      pan: "",
      expiry: ""
    },
    cardholder: {
      cardholderName: "",
      email: "",
      homePhone: "",
      mobilePhone: "",
      workPhone: "",
      billingAddressMatchShipping: null,
      billingAddress: {
        country: "724",
        state: "",
        city: "",
        postCode: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: ""
      },
      shippingAddress: null
    }
  };

  loading = false;

  created() {
    this.onFields();
    this.$nextTick(() => {
      this.onCardDefaults(this.defaults);
      /*setTimeout(() => {
        this.onCardDefaults(this.defaults);
      }, 200);*/
    });
  }

  get enabledFields() {
    return [
      this.detailFields.email,
      this.detailFields.homePhone,
      this.detailFields.mobilePhone,
      this.detailFields.workPhone
    ].filter((f) => f.visible);
  }

  get lang() {
    return lang.getStrings(this.langKey);
  }

  @Watch("payCardInfo")
  onPayCardInfo(newValue: PayCardInfo) {
    this.workingInfo.details.cvv = newValue.cardCvv;
    this.workingInfo.details.pan = newValue.cardNumber;
    this.workingInfo.details.expiry = newValue.cardExpiry;
    this.workingInfo.cardholder.cardholderName = newValue.cardName;
  }

  @Watch("defaults")
  onCardDefaults(value: Partial<PublicCardholderDetails>) {
    if (value.cardholderName) this.workingInfo.cardholder.cardholderName = value.cardholderName;
    if (value.email) this.workingInfo.cardholder.email = value.email;
    if (value.homePhone) this.workingInfo.cardholder.homePhone = value.homePhone;
    if (value.mobilePhone) this.workingInfo.cardholder.mobilePhone = value.mobilePhone;
    if (value.workPhone) this.workingInfo.cardholder.workPhone = value.workPhone;
    if (value.billingAddress) this.workingInfo.cardholder.billingAddress = value.billingAddress;
    if (value.shippingAddress) this.workingInfo.cardholder.shippingAddress = value.shippingAddress;
    if (value.billingAddressMatchShipping !== null && value.billingAddressMatchShipping !== undefined) {
      this.workingInfo.cardholder.billingAddressMatchShipping = value.billingAddressMatchShipping;
    } else if (value.shippingAddress && !value.billingAddress) {
      // If shipping is assigned but nothing is assigned to billing we assume it's the same by default
      this.workingInfo.cardholder.billingAddressMatchShipping = true;
    }
  }

  @Watch("fields")
  onFields() {
    let cardNameFieldMode: CardNameFieldMode = "hidden";
    if (this.fields) {
      for (const field of this.fields) {
        switch (field.type) {
          case "CardholderName":
            cardNameFieldMode = field.displayRule;
            break;
          case "Email":
            this.setField(this.detailFields.email, field);
            break;
          case "HomePhone":
            this.setField(this.detailFields.homePhone, field);
            break;
          case "MobilePhone":
            this.setField(this.detailFields.mobilePhone, field);
            break;
          case "WorkPhone":
            this.setField(this.detailFields.workPhone, field);
            break;
          case "BillingAddress":
            this.detailFields.billingAddress.forEach((f) => this.setField(f, field));
            this.billingAddressEnabled = true;
            break;
        }
      }
    }
    this.cardNameFieldMode = cardNameFieldMode;
  }

  @Watch("workingInfo", { deep: true })
  onCardDetails(newValue: FullCardInfo) {
    const sanitizedValue: FullCardInfo = {
      details: { ...newValue.details },
      cardholder: { ...newValue.cardholder }
    };
    sanitizedValue.details.pan = sanitizedValue.details.pan.replace(/\s/g, "");
    if (sanitizedValue.cardholder.billingAddressMatchShipping) {
      sanitizedValue.cardholder.billingAddress = null;
    }
    for (const prop in sanitizedValue.cardholder) {
      if (!sanitizedValue.cardholder[prop as keyof PublicCardholderDetails])
        sanitizedValue.cardholder[prop as keyof PublicCardholderDetails] = null;
    }
    this.$emit("input", sanitizedValue);
  }

  validate() {
    return (this.$refs.cardForm as VueForm).validate();
  }

  makeField(name: string, type: CustomFieldType, label: string, placeholder?: string): CustomFormValue {
    return {
      field: {
        name: name,
        required: false,
        readOnly: false,
        label: type == "checkbox" ? label : "",
        defaultValue: "",
        type,
        placeholder
      },
      originalType: type,
      visible: false,
      label,
      detailsProp: name
    };
  }

  setField(customField: CustomFormValue, field: CardFormField) {
    customField.visible = true;
    if (customField.field) {
      customField.field.type = customField.originalType;
      switch (field.displayRule) {
        case "mandatory":
          customField.field.required = true;
          customField.field.readOnly = false;
          break;
        case "optional":
          customField.field.required = false;
          customField.field.readOnly = false;
          break;
        case "readOnly":
          customField.field.required = false;
          customField.field.readOnly = true;
          break;
      }
      //customField.field.defaultValue = field.defaultValue || "";
    }
  }

  printAddress(addr: Address) {
    const country = searchCountry(this.langKey, { isoNum: addr.country });
    return [
      addr.addressLine1,
      addr.addressLine2,
      addr.addressLine3,
      addr.postCode,
      addr.city,
      addr.state,
      country?.name || addr.country
    ]
      .filter((a) => !!a)
      .join(", ");
  }
}

interface CustomFormValue {
  field: CustomFieldConfig | null;
  originalType: CustomFieldType;
  label: string;
  detailsProp: string;
  visible: boolean;
}

// This is a helper type, with known methods of v-form
interface VueForm extends Vue {
  validate(): boolean;
  $el: HTMLFormElement;
}
