import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import TxnForm from "../views/TxnForm.vue";
import TxnDirect from "../views/TxnDirect.vue";
import ResultView from "../views/Result.vue";
import BizumRedirect from "../views/BizumRedirect.vue";
import ChallengeRedirect from "../views/ChallengeRedirect.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/form/:requestID",
    name: "Online Transaction: Transaction Form",
    component: TxnForm
  },
  {
    path: "/direct/:requestID",
    name: "Online Transaction: Process Transaction",
    component: TxnDirect
  },
  {
    path: "/result",
    name: "Online Transaction: Result",
    component: ResultView
  },
  {
    path: "/bizum/:requestID",
    name: "Online Transaction: Bizum Result",
    component: BizumRedirect
  },
  {
    path: "/challenge/:requestID",
    name: "Send PaRes Form",
    component: ChallengeRedirect
  },
  {
    path: "/test",
    name: "Test",
    component: () => import(/* webpackChunkName: "dev" */ "../views/Test.vue")
  },
  {
    path: "/util",
    name: "Util",
    component: () => import(/* webpackChunkName: "dev" */ "../views/Util.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
