import axios, { AxiosResponse, Method } from "axios";
import { gatherBrowserData } from "./browserData";
import {
  ResolveResponse,
  PrepareRequest,
  PrepareResponse,
  TransactionInfoResponse,
  FullCardInfo,
  ResolveRequest,
  BizumResponse,
  RedirectResponse,
  FailedResponse,
  QueryRtpPaymentResponse
} from "./types";
import config from "../config";
import { logError } from "@/util";
import { sleepFor } from "@/util/other";

const MAX_RETRIES = 5;
const ERROR_WAIT_MS = 2000;

export class BackendApi {
  rootUrl = config.functionsURL;

  async transactionInfo(requestID: string): Promise<TransactionInfoResponse> {
    const response = await this.request<null, TransactionInfoResponse>(
      MAX_RETRIES,
      "get",
      `${this.rootUrl}transactionInfo?req_id=${requestID}`,
      null
    );
    return response.data;
  }

  async transactionPrepare(requestID: string, cardInfo: FullCardInfo | null): Promise<PrepareResponse> {
    const request: PrepareRequest = {
      requestID,
      browserInfo: gatherBrowserData()
    };
    if (cardInfo && cardInfo.details) {
      request.card = cardInfo.details;
    }
    if (cardInfo && cardInfo.cardholder) {
      request.cardholder = cardInfo.cardholder;
    }

    const response = await this.request<PrepareRequest, PrepareResponse>(
      MAX_RETRIES,
      "post",
      `${this.rootUrl}transactionPrepare`,
      request
    );
    return response.data;
  }

  async transactionResolve(
    requestID: string,
    forceResolve: boolean,
    dccChoice?: "yes" | "no"
  ): Promise<ResolveResponse> {
    const request: ResolveRequest = {
      requestID,
      browserInfo: gatherBrowserData(),
      forceResolve: forceResolve,
      dccChoice
    };
    const response = await this.request<ResolveRequest, ResolveResponse>(
      MAX_RETRIES,
      "post",
      `${this.rootUrl}transactionResolve`,
      request
    );
    return response.data;
  }

  async startBizumPayment(
    requestID: string,
    phoneNumber: string
  ): Promise<{ data: BizumResponse | RedirectResponse | FailedResponse }> {
    return await axios.post(`${this.rootUrl}bizumValidatePhone`, {
      requestID,
      phoneNumber
    });
  }

  async queryChallengeStatus(requestID: string, i = 0): Promise<void> {
    const txnInfo = await this.transactionInfo(requestID);
    if (i < 10) {
      if (txnInfo.status === "finished") {
        // Redirect to result.
        if (txnInfo.result?.resultURL) window.location.href = txnInfo.result.resultURL;
      } else if (txnInfo.status === "choosingDCC") {
        // Redirect to DCC
        window.location.href = `/direct/${requestID}`;
      } else {
        // Retry
        await sleepFor(3000);
        await this.queryChallengeStatus(requestID, ++i);
      }
    }
  }

  async queryBizumPaymentStatus(requestID: string): Promise<void> {
    const queryTxn = await axios.post(`${this.rootUrl}bizumQueryRtpPayment`, { requestID });
    const body: QueryRtpPaymentResponse = queryTxn.data;
    if (body.status === "pending") {
      await sleepFor(5000);
      await this.queryBizumPaymentStatus(requestID);
    } else {
      if (body.redirectUrl) window.location.href = body.redirectUrl;
    }
  }

  async request<TReq, TResp>(retries: number, method: Method, url: string, data?: TReq): Promise<AxiosResponse<TResp>> {
    try {
      return await axios.request<TResp>({
        method: method,
        url: url,
        data: data
      });
    } catch (err) {
      logError(
        `HTTP Error (attempt ${MAX_RETRIES - retries}) ${method} (url=${url}) ${(err as Error).message}`,
        err as Error
      );
      if (retries == 0) throw err;
      await sleepFor(ERROR_WAIT_MS);
      return this.request(retries - 1, method, url, data);
    }
  }
}
