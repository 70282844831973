
import { Component, Vue } from "vue-property-decorator";
import * as app from "@/application";
import { logError, logInfo, moneyToString } from "@/util";
import {
  DCCInformation,
  FailedResponse,
  FullCardInfo,
  RedirectResponse,
  TransactionInfoResponse
} from "@/domain/types";
import TxnProcessForm from "@/components/TxnProcessForm.vue";
import CardForm from "@/components/CardForm.vue";
import DccChoice from "@/components/DccChoice.vue";
import TxnStatusPanel, { TxnStatusPanelID } from "@/components/TxnStatusPanel.vue";
import PoweredBy from "@/components/PoweredBy.vue";
import TxnProcessBizumForm from "@/components/TxnProcessBizumForm.vue";
import MerchantLogo from "@/components/MerchantLogo.vue";
import { getStrings } from "@/lib/language";
import { getPaytefCardCodeInfo, paytefCardTypeCodes } from "@/components/payment";
import CustomPhoneInput from "@/components/inputs/CustomPhoneInput.vue";

type UIPanel = TxnStatusPanelID | "transaction" | "dcc";

@Component({
  components: {
    TxnProcessForm,
    CardForm,
    TxnStatusPanel,
    DccChoice,
    PoweredBy,
    MerchantLogo,
    TxnProcessBizumForm,
    CustomPhoneInput
  }
})
export default class TxnForm extends Vue implements app.TxnView {
  panelID: UIPanel = "loading";
  requestID = "";
  txnInfo: Partial<TransactionInfoResponse> = {
    form: {
      fields: [],
      defaultValues: {
        cardholderName: "",
        email: "",
        homePhone: "",
        mobilePhone: "",
        workPhone: "",
        billingAddressMatchShipping: null,
        billingAddress: null,
        shippingAddress: null
      }
    }
  };
  cardInfo: FullCardInfo = {
    details: {
      pan: "",
      expiry: "",
      cvv: ""
    },
    cardholder: {
      cardholderName: null,
      email: null,
      homePhone: null,
      mobilePhone: null,
      workPhone: null,
      billingAddressMatchShipping: null,
      billingAddress: null,
      shippingAddress: null
    }
  };
  processing = false;
  paymentMethods = ["card", "bizum"];
  dccInfo: DCCInformation | null = null;
  selectedPaymentMethod: "card" | "bizum" = "card";
  selectedButton = 0;
  phone = "";
  showPaymentDenied = false;
  showPaymentDeniedMessage = "";
  waitingConfirmation = false;

  get customCss() {
    return this.txnInfo?.merchant?.branding?.puceCustomCssURL || null;
  }

  get langKey() {
    return this.txnInfo?.language;
  }

  get lang() {
    return getStrings(this.langKey);
  }

  get accentColor(): string {
    return app.getPrimaryColor(this.txnInfo?.merchant);
  }

  get cardBrandImages(): string[] {
    let brands = this.txnInfo?.merchant?.cardBrands;
    if (!brands) return [];
    if (brands.length === 0) brands = paytefCardTypeCodes;
    const images: string[] = [];
    brands.forEach((b) => {
      const info = getPaytefCardCodeInfo(b);
      if (info?.theme) images.push(info.theme.ccicon);
      if (info?.theme2) images.push(info.theme2.ccicon);
    });
    return images;
  }

  changeSelectedPaymentMethod(method: "card" | "bizum"): void {
    this.selectedPaymentMethod = method;
  }

  txnProcessForm(): TxnProcessForm {
    return this.$refs.txnProcessForm as TxnProcessForm;
  }

  txnStatusPanel() {
    return this.$refs.txnStatusPanel as TxnStatusPanel;
  }

  setPanelID(panelID: UIPanel) {
    this.panelID = panelID;
    this.txnStatusPanel().setPanel(panelID === "dcc" || panelID === "transaction" ? null : panelID);
  }

  get txnAmount(): string {
    return moneyToString(this.txnInfo?.amount, this.txnInfo?.currency);
  }

  async created() {
    const requestID = this.$route.params["requestID"];
    if (!requestID) {
      logError("TxnForm: Invalid transaction ID", null, { requestID });
      return;
    }
    logInfo(`TxnForm created: ${requestID}`);
    this.requestID = requestID;
    this.$nextTick(() => {
      this.loadTransactionInfo();
    });
  }

  async loadTransactionInfo() {
    try {
      this.setPanelID("loading");
      this.txnInfo = await this.$puceApp.backend.transactionInfo(this.requestID);
      logInfo("Transaction Info", this.txnInfo);
      if (this.txnInfo.shouldBeExpired) {
        this.setPanelID("expired");
      } else if (this.txnInfo.result) {
        this.setPanelID("alreadyProcessed");
      } else {
        if (this.txnInfo && this.txnInfo.dccInfo) {
          this.onDCCInfo(this.txnInfo.dccInfo);
        } else {
          this.setPanelID("transaction");
        }
      }
    } catch (err) {
      logError(`Error loading transaction info '${this.requestID}'`, err as Error, { requestID: this.requestID });
      this.setPanelID("errorRetry");
    }
  }

  onDCCInfo(dccInfo: DCCInformation) {
    this.dccInfo = dccInfo;
    this.setPanelID("dcc");
  }

  async cmdPay() {
    if (!(this.$refs.cardForm as CardForm).validate()) return;
    try {
      this.processing = true;
      await this.txnProcessForm().startProcess(this.requestID, this.cardInfo);
    } catch (err) {
      logError(`Start process prepare failed '${this.requestID}'`, err as Error, { requestID: this.requestID });
      this.setPanelID("error");
      this.processing = false;
    }
  }

  get disableBizumButton() {
    if (this.phone && !this.processing) return (this.$refs.phoneInput as CustomPhoneInput).isPhoneInvalid();
    return true;
  }

  // BIZUM PAYMENT
  async cmdBizumPay() {
    // VALIDACION
    if ((this.$refs.phoneInput as CustomPhoneInput).isPhoneInvalid()) return;

    try {
      this.processing = true;
      const phone = "+" + this.phone.substring(0, 11);

      // START PAYMENT
      const res = await this.$puceApp.backend.startBizumPayment(this.requestID, phone);

      // PROCESS RESPONSE
      if (res.data?.method === "redirect") {
        // REDIRECT
        (this.$refs.txnformBizum as TxnProcessBizumForm).submitRedirectForm(res.data as RedirectResponse);
      } else if (res.data?.method === "rtp" || res.data?.method === "validation") {
        // RTP | VALIDATION FAILED (phone without Bizum)
        if (res.data?.method === "rtp") this.waitingConfirmation = true;
        await this.$puceApp.backend.queryBizumPaymentStatus(this.requestID);
      } else {
        // Method: Invalid phone Error.
        const data = res.data as FailedResponse;
        this.showPaymentDenied = true;
        this.showPaymentDeniedMessage = data.errorData.message;
        this.processing = false;
        setTimeout(() => {
          this.showPaymentDenied = false;
        }, 5000);
      }
    } catch (err) {
      logError("Prepare failed", err as Error, this.requestID);
      this.setPanelID("error");
      this.processing = false;
    }
  }
}
