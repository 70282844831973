const devEnv = "development";
const isProd = (process.env.NODE_ENV || devEnv) !== devEnv;

const isPreprod = window.location && window.location.hostname.startsWith("paytef-puce-preprod.");

export default {
  functionsURL: isProd
    ? "https://europe-west1-paytef-puce.cloudfunctions.net/" + (isPreprod ? "preprod/" : "")
    : "http://localhost:5013/paytef-puce/europe-west1/preprod/",
  logURL: isProd
    ? "https://europe-west1-paytef-puce.cloudfunctions.net/log"
    : "http://localhost:5013/paytef-puce/europe-west1/log/"
};
